import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import { Auth0Provider } from '@auth0/auth0-react'
import Auth0Wrapper from './components/Auth0Wrapper' // We'll create this component
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory'
import { createBrowserHistory } from 'history'

// const domain = 'dev-w2nvmnzr.eu.auth0.com'
const domain = 'login.routines.world'
const clientId = '0Hx6RgMkoZATGfhcgP1ljTJ7E7rvSrhG'
const audience = 'http://localhost:8080/'
const history = createBrowserHistory()

// ReactDOM.render(
//   <Auth0Provider
//     domain={domain}
//     clientId={clientId}
//     redirectUri={window.location.origin}
//     audience={audience}
//   >
//     <Provider store={store}>
//       <Router>
//         <Auth0Wrapper>
//           <App />
//         </Auth0Wrapper>
//       </Router>
//     </Provider>
//   </Auth0Provider>,
//   document.getElementById('root'),
// )

ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={history}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.register()
